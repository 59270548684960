$(document).ready(function(){
    $(document).foundation();
    Config.image_url = '/images/MARTIN_AGE/';
    $('#loader').css('display', 'none');
    Martin.init();
});
var Martin = (function() {
    "use strict";
    var $scope, sideNav = $('.side-nav'), background = $('.background'), background_v2 = $('.background_v2'), content = $('.content'), mainSearch = $('#main-search'), main = $('.main'), searchBar = $('.search-bar'), filterProperty = $('#filter_property'), btnMap = $('.btn-map'), hamburger = $('.hamburger'), loupe = $('.loupe'), more = $('.more'), uiCookies = $('.ui-cookies'), videoHolder = $('.video-holder'), sliderHolder = $('.slider-holder'), searchBudgetMin = $('#property_search_budgetMin'), searchBudgetMax = $('#property_search_budgetMax'), searchBudgetMin1 = $('#property_search_budgetMin1'), searchBudgetMax1 = $('#property_search_budgetMax1'), searchSurfaceMin = $('#property_search_surfaceMin'), searchPropertyRef = $('#property_search_ref');
    function init() {
        initPage();
        MultiSelect.init();
        ChosenTouch.init();
        bindUIActions();
    }
    function upd(data) {
        $scope.data = data;
        $('input').trigger('input');
        $('#property_search_nbRoom').val($scope.data.nbRoom);
        $('#property_search_nbBedRoom').val($scope.data.nbBedRoom);
        updateChosen($('#property_search_town'));
        updateChosen($('#property_search_type'));
        updateChosen($('#property_search_town1'));
        updateChosen($('#property_search_type1'));
        updateIndicators();
    }
    function bindUIActions() {
        $('p.read-more').readmore({
            speed: 75,
            lessLink: '<a href="#">Fermer</a>',
            moreLink: '<a href="#">Lire la suite</a>'
        });
        $('.chosen-select').chosen({
            search_contains: true
        });
        uiCookies.on('click', function() {
            $('#ui-id-cookies').css({
                '-webkit-transform': 'translateY(100%)',
                '-ms-transform': 'translateY(100%)',
                'transform': 'translateY(100%)'
            });
        });
        hamburger.on("click", function() {
            toggleMenu();
        });
        $('.search-field, input[type="text"], input[type="number"], select').focusin(function() {
            $('.custom-dropdown').css('display', '');
            $('.custom-dropdown').css('display', 'none');
        });
        more.on("click", function() {
            updateChosen($('#property_search_town'));
            updateChosen($('#property_search_type'));
            updateIndicators();
            displayEngine();
        });
        loupe.on("click", function() {
            updateChosen($('#property_search_town'));
            updateChosen($('#property_search_type'));
            updateIndicators();
            displayEngine();
        });
        btnMap.on("click", function() {
            if(parseInt($(this).css('margin-right'))  > 0) {
                if(window.innerWidth < 1025) {
                    content.css('width', '100%');
                }
                GoogleMap.toggleMap(false);
            } else {
                if(window.innerWidth < 1025) {
                    closeMenu();
                    //content.css('width', '58.3333333%');
                }
                GoogleMap.toggleMap(true);
            }
        });
        filterProperty.submit(function(e) {
            e.preventDefault();
            $("#submit").trigger('click');
        });
        $('.clear-filters').on("click", function(e) {
            e.preventDefault();
            resetForm();
        });
        searchBudgetMin.blur(function(){
            $("#property_search_budgetMin1").val( $(this).val() );
        });
        searchBudgetMax.blur(function(){
            $("#property_search_budgetMax1").val( $(this).val() );
        });
        searchBudgetMin1.blur(function(){
            $("#property_search_budgetMin").val( $(this).val() );
        });
        searchBudgetMax1.blur(function(){
            $("#property_search_budgetMax").val( $(this).val() );
        });
        searchSurfaceMin.blur(function(){
            updateIndicators();
        });
        searchPropertyRef.blur(function(){
            updateIndicators();
        });
        $('#property_search_nbRoom, #property_search_nbBedRoom').change(updateIndicators);
        $('.reveal-overlay .close-button').on("click", function(){
            $('.reveal-overlay').removeClass('fdn-reveal');
            hamburger.css('display', 'block');
            loupe.css('display', 'block');
        });
    }
    function initModal(trigger, modal) {
        console.log(trigger);
        trigger.on("click", function() {
            modal.addClass('fdn-reveal');
            hamburger.css('display', 'none');
            loupe.css('display', 'none');
        });
    }
    function updateIndicators() {
        var DATA = [
            $('#property_search_nbRoom').val(),
            $('#property_search_nbBedRoom').val(),
            $scope.data.surfaceMin,
            $scope.data.ref
        ], i, total = 0;
        for(i = 0; i < DATA.length; i++) {
            if(DATA[i] && DATA[i] != null) {
                total++;
            }
        }
        if(total > 0) {
            $('a.more').find('.indicator').text(total.toString()).css('display', 'block');
        } else {
            $('a.more').find('.indicator').text(total.toString()).css('display', 'none');
        }
    }
    function initPage() {
        if(main && main != null && $('.main').length > 0) {
            var CLASSES = $('.main').attr('class').split(/\s+/), PAGE = [], i = 0;
            for(i = 0; i < CLASSES.length; i++) {
                if(CLASSES[i] != 'main') {
                    PAGE.push(CLASSES[i]);
                }
            }
            if(PAGE.length == 1) {
                Config.page = PAGE[0];
            } else {
                Config.page = PAGE.join('');
            }
            setNav();
            switch(Config.page) {
                case 'home':
                    $('.filter-property').attr('novalidate', '');
                    if(!videoHolder.css('display') && !sliderHolder.css('display')) {
                        window.onresize = imgResize;
                        imgResize();
                    }
                    if(sliderHolder.css('display')) {
                        mainSearch.css('top', '0%');
                        content.css('top', 800);
                    }
                    initSVG();
                    break;
                case 'homev2':
                    $('.filter-property').attr('novalidate', '');
                    $('nav.side-nav').addClass('v2');
                    break;
                case 'bien':
                    Slider.init();
                    $('.fancybox').fancybox();
                    break;
                case 'list':
                    $scope = angular.element('#app').scope();
                    $('body').css({
                        'overflow': 'hidden',
                        'background-color': '#f2f2f2'
                    });
                    $("#property_search_town1").append($("#property_search_town").html());
                    $("#property_search_type1").append($("#property_search_type").html());
                    break;
                case 'contact':
                case 'estim2':
                case 'sitemap':
                case 'pn':
                case 'esp':
                case 'generic':
                case 'users':
                    break;
                case "usersagence":
                case 'usersusr-file':
                case 'agences':
                    loadScript();
                    break;
            }

            var TRIGGERS = [$('*[data-open="alert"]'), $('*[data-open="resp"]'), $('*[data-open="sold"]'), $('*[data-open="reco"]'), $('*[data-open="share"]'), $('*[data-open="write_rating"]'), $('*[data-open="resp_rate"]')],
                MODALS = [$('#alert.reveal').parent(), $('#resp.reveal').parent(), $('#sold.reveal').parent(), $('#reco.reveal').parent(), $('#share.reveal').parent(), $('#write_rating.reveal').parent(), $('#resp_rate.reveal').parent()],
                elem;

            $('.ui-resp').each(function(){
                elem = $('#' + $(this).data('open'));
                initModal($(this), elem.parent());
            });
            for(i = 0; i < MODALS.length; i++) {
                initModal(TRIGGERS[i], MODALS[i]);
            }
            if(Config.page != 'home') {
                window.onresize = null;
            }
        }
        var pubHome = $('#campaign-home');
        if(pubHome.css('display')) {
            var img = new Image();
            img.onload = function() {
                if(BrowserInfo.isMobile) {
                    resizePub();
                }
                pubHome.css({
                    'margin-top': '-' + ((pubHome.height() / 2) + 1) + 'px',
                    'display': 'block'
                });
                pubHome.removeClass('hidden');
            };
            img.src = pubHome.find('img').attr('src');
        }
    }
    function setNav() {
        var url = window.location.href;
        $('.vertical.menu li a').each(function(){
            if(url.indexOf($(this).data('id')) > -1) {
                $(this).addClass('active');
            }
        });
    }
    function loadScript() {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCFhZbc0zfGusLksxcmw7ap_OuOsBDq2SM&language=fr';
        document.body.appendChild(script);
    }
    function resetForm() {
        $(".chosen-select").val('').trigger("chosen:updated");
        var FIELDS = [searchBudgetMin, searchBudgetMax, searchBudgetMin1, searchBudgetMax1, searchSurfaceMin, searchPropertyRef];
        for(var i = 0; i < FIELDS.length; i++) {
            FIELDS[i].val('');
        }
        $('input[type=radio][name=pieces]').each(function() {
            $(this).removeAttr("checked");
        });
        $('#property_search_nbRoom > option').each(function(){
            $(this).removeAttr("selected");
        });
        $('#property_search_nbRoom').prop('selectedIndex',0).val(0);
        $('#property_search_nbBedRoom > option').each(function(){
            $(this).removeAttr("selected");
        });
        $('#property_search_nbBedRoom').prop('selectedIndex',0).val(0);
        $scope.data.nbRoom = null;
        $scope.data.nbBedRoom = null;
        $scope.data.surfaceMin = null;
        $scope.data.ref = null;
        updateIndicators();
        $("#submit").trigger('click');
    }
    function initSVG() {
        $('img.svg').each(function(){
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');
            $.get(imgURL, function(data) {
                var $svg = $(data).find('svg');
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }
                $svg = $svg.removeAttr('xmlns:a');
                if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                    $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
                }
                $img.replaceWith($svg);
            }, 'xml');
        });
    }
    function updateChosen($element) {
        $element.trigger("chosen:updated");
        if($scope.data.ville == null || $scope.data.ville == []) {
            $('.main-ville').data('placeholder', 'Choisissez une ville');
            $('.sub-ville').data('placeholder', 'Choisissez une ville');
        }
        if($scope.data.type == null || $scope.data.type == []) {
            $('.main-type').data('placeholder', 'Choisissez un type');
            $('.sub-type').data('placeholder', 'Choisissez un type');
        }
    }
    function displayEngine() {
        var wrapper = $('main.main.list .wrapper');
        wrapper.addClass('extended');
        hamburger.css('display', 'none');
        loupe.css('display', 'none');
        filterProperty.css('display', 'none');
        $('.engine').css({
           '-ms-transform': 'translateY(0%)',
           '-moz-transform': 'translateY(0%)',
           '-webkit-transform': 'translateY(0%)',
           'transform': 'translateY(0%)'
        });
        $('.close-menu, .annuler').on("click", function() {
            updateChosen($(".sub-ville"));
            updateChosen($(".sub-type"));
            updateIndicators();
            wrapper.removeClass('extended');
            if(window.innerWidth < 1025) {
                hamburger.css('display', 'block');
                loupe.css('display', 'block');
                filterProperty.css('display', '');
            } else {
                filterProperty.css('display', 'block');
            }
            $('.engine').css({
                '-ms-transform': 'translateY(100%)',
                '-moz-transform': 'translateY(100%)',
                '-webkit-transform': 'translateY(100%)',
                'transform': 'translateY(100%)'
            });
        });
    }
    function closeMenu() {
        sideNav.removeClass('open');
        background.removeClass('nav-anim');
        background_v2.removeClass('nav-anim');
        content.removeClass('nav-anim');
        mainSearch.removeClass('nav-anim');
        searchBar.removeClass('nav-anim');
        sliderHolder.removeClass('nav-anim');
        videoHolder.removeClass('nav-anim');
        sideNav.css({
            'transform': 'translateX(-100%)',
            '-ms-transform': 'translateX(-100%)',
            '-webkit-transform': 'translateX(-100%)'
        });
    }
    function toggleMenu() {
        var open = sideNav.hasClass('open'), perc = open ? -100 : 0, width = window.innerWidth;
        if(width < 641)
            loupe.css('display') == 'none' ? loupe.css('display', 'block') : loupe.css('display', 'none');
        sideNav.toggleClass('open');
        background.toggleClass('nav-anim');
        background_v2.toggleClass('nav-anim');
        content.toggleClass('nav-anim');
        mainSearch.toggleClass('nav-anim');
        searchBar.toggleClass('nav-anim');
        sliderHolder.toggleClass('nav-anim');
        videoHolder.toggleClass('nav-anim');
        sideNav.css({
            'transform': 'translateX(' + perc + '%)',
            '-ms-transform': 'translateX(' + perc + '%)',
            '-webkit-transform': 'translateX(' + perc + '%)'
        });
    }
    function imgResize() {
        var hDoc = window.innerHeight, minHeight = 500;
        if(hDoc > minHeight) {
            content.css('top', hDoc);
            background.css('height', hDoc);
        } else {
            content.css('top', minHeight);
            background.css('height', minHeight);
        }

    }
    function initLocal(lng, lat, add_marker) {
        var mapOptions = {
            center: new google.maps.LatLng(lng, lat),
            zoom: (add_marker===1) ? 16 : 14,
            scrollwheel: false,
            streetViewControl: false,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                mapTypeIds: [
                    google.maps.MapTypeId.ROADMAP,
                    google.maps.MapTypeId.TERRAIN
                ]
            }
        };
        var map = new google.maps.Map(document.getElementById('carte'), mapOptions);
        if ( add_marker === 1 ) {
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(lng, lat)
            });
            marker.setMap(map);
        }
        //GoogleMap.searchNearby(map);  // Pour activer les POI
    }
    return {
        init:init,
        initLocal: initLocal,
        upd:upd
    }
})();
